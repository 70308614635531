// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bkp-blog-js": () => import("./../../../src/pages/_bkp/blog.js" /* webpackChunkName: "component---src-pages-bkp-blog-js" */),
  "component---src-pages-bkp-sobre-js": () => import("./../../../src/pages/_bkp/sobre.js" /* webpackChunkName: "component---src-pages-bkp-sobre-js" */),
  "component---src-pages-bkp-tags-index-js": () => import("./../../../src/pages/_bkp/tags/index.js" /* webpackChunkName: "component---src-pages-bkp-tags-index-js" */),
  "component---src-pages-consultoria-js": () => import("./../../../src/pages/consultoria.js" /* webpackChunkName: "component---src-pages-consultoria-js" */),
  "component---src-pages-contato-index-js": () => import("./../../../src/pages/contato/index.js" /* webpackChunkName: "component---src-pages-contato-index-js" */),
  "component---src-pages-contato-obrigada-js": () => import("./../../../src/pages/contato/obrigada.js" /* webpackChunkName: "component---src-pages-contato-obrigada-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-materiais-js": () => import("./../../../src/pages/materiais.js" /* webpackChunkName: "component---src-pages-materiais-js" */),
  "component---src-pages-pagamento-aprovado-js": () => import("./../../../src/pages/pagamento-aprovado.js" /* webpackChunkName: "component---src-pages-pagamento-aprovado-js" */),
  "component---src-pages-pedido-recebido-js": () => import("./../../../src/pages/pedido-recebido.js" /* webpackChunkName: "component---src-pages-pedido-recebido-js" */),
  "component---src-pages-planejamento-curricular-js": () => import("./../../../src/pages/planejamento-curricular.js" /* webpackChunkName: "component---src-pages-planejamento-curricular-js" */),
  "component---src-pages-registudy-js": () => import("./../../../src/pages/registudy.js" /* webpackChunkName: "component---src-pages-registudy-js" */),
  "component---src-pages-tira-duvidas-js": () => import("./../../../src/pages/tira-duvidas.js" /* webpackChunkName: "component---src-pages-tira-duvidas-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

